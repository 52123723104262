import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useFormContext } from "react-hook-form";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { uuid } from "short-uuid";

import PhaseForm from "./PhaseForm";

import { ProjectPhotoPhase } from "API";

function NextArrowIcon({
  insertable,
  onClick,
}: {
  insertable?: boolean;
  onClick?: () => void;
}) {
  const [isHovering, setIsHovered] = useState(false);

  if (!insertable)
    return (
      <div className="flex items-center">
        <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
      </div>
    );

  const onMouseEnter = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);
  return (
    <div
      className="flex items-center cursor-pointer"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {!isHovering && <ArrowForwardIosIcon sx={{ fontSize: 14 }} />}
      {isHovering && <AddCircleIcon sx={{ fontSize: 20 }} onClick={onClick} />}
    </div>
  );
}

interface PhaseChipProps {
  phase: ProjectPhotoPhase;
  setPhotoPhases: Dispatch<SetStateAction<ProjectPhotoPhase[]>>;
}

function PhaseChip({ phase, setPhotoPhases }: PhaseChipProps) {
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDelete = () => {
    setPhotoPhases((prev: ProjectPhotoPhase[]) =>
      prev.filter((p) => p.id !== phase?.id)
    );
  };

  const handleUpdate = (data: any) => {
    if (!data.name) return;
    setPhotoPhases((prev: ProjectPhotoPhase[]) =>
      prev.map((p) => (p.id === phase?.id ? data : p))
    );
    handleClose();
  };

  const phaseName = (phase: ProjectPhotoPhase) => {
    if (phase.requiresPhoto) return `*${phase?.name}`;
    return phase?.name;
  };

  return (
    <>
      <Chip
        variant="outlined"
        key={phase?.id}
        label={phaseName(phase)}
        onClick={handleClick}
        onDelete={handleDelete}
      />
      <PhaseForm
        title="写真フェーズ編集"
        open={open}
        onClose={handleClose}
        phase={phase}
        onSubmit={handleUpdate}
      />
    </>
  );
}

export default function PhotoPhases() {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState<number>(0);
  const { setValue } = useFormContext();
  const [photoPhases, setPhotoPhases] = useState<ProjectPhotoPhase[]>([]);

  useEffect(() => {
    setValue("photoPhases", photoPhases);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoPhases]);

  const handleClick = (idx: number) => {
    setIndex(idx);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleCreate = (data: any) => {
    if (!data.name) return;
    let newArray = photoPhases.slice();
    newArray.splice(index, 0, { id: uuid(), ...data });
    setPhotoPhases(newArray);
    handleClose();
  };

  return (
    <Stack direction="row" sx={{ flexWrap: "wrap", gap: 0.5 }}>
      <PhaseForm
        title="写真フェーズ挿入"
        open={open}
        onClose={handleClose}
        onSubmit={handleCreate}
        phase={null}
      />
      <Chip label="入荷" />
      <NextArrowIcon insertable onClick={() => handleClick(0)} />
      {photoPhases?.map((phase, idx) => (
        <>
          <PhaseChip
            phase={phase as ProjectPhotoPhase}
            setPhotoPhases={setPhotoPhases}
          />
          <NextArrowIcon insertable onClick={() => handleClick(idx + 1)} />
        </>
      ))}
      <Chip label="出荷" />
    </Stack>
  );
}
