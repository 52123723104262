import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef, ReactNode } from "react";

import { RedirectTypes } from "utils/project";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface FormProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  title: string;
  submitText?: string;
  onSave: () => void;
  children: ReactNode | ReactNode[];
  // redirectする際の設定をするもので、実際のredirect処理はonSave等外部で実施する必要がある
  // redirectTypeはSubmit時のevent.target.nameを通して利用可能
  redirectConfig?: {
    type: RedirectTypes;
    text: string;
  };
}

export default function Form({
  open,
  setOpen,
  title,
  submitText = "保存",
  onSave,
  children,
  redirectConfig,
}: FormProps) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <form onSubmit={onSave}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
            <Button onClick={onSave} type="submit" autoFocus color="inherit">
              {submitText}
            </Button>
            {redirectConfig && (
              <Button
                sx={{ ml: 2 }}
                onClick={onSave}
                type="submit"
                autoFocus
                color="inherit"
                name={redirectConfig.type}
              >
                {redirectConfig.text}
              </Button>
            )}
          </Toolbar>
        </AppBar>
        {children}
      </form>
    </Dialog>
  );
}
