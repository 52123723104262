import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";

export default function Name() {
  const { control } = useFormContext();

  return (
    <Controller
      render={({ field }) => (
        <TextField label="案件名" variant="outlined" required {...field} />
      )}
      control={control}
      name="name"
      rules={{
        required: "必須項目です",
      }}
    />
  );
}
