import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

export default function ProjectType() {
  const { control } = useFormContext();
  return (
    <Controller
      render={({ field }) => (
        <TextField select label="案件タイプ" variant="outlined" {...field}>
          <MenuItem value="PACK">梱包</MenuItem>
          <MenuItem value="OTHER">その他</MenuItem>
        </TextField>
      )}
      control={control}
      name="type"
      defaultValue="PACK"
      rules={{
        required: "必須項目です",
      }}
    />
  );
}
