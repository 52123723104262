import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

import { ProjectPhotoPhase } from "API";

interface Props {
  title: string;
  open: boolean;
  onSubmit: (data: any) => void;
  onClose: () => void;
  phase?: ProjectPhotoPhase | null;
}

export default function PhaseForm({
  title,
  open,
  phase,
  onSubmit,
  onClose,
}: Props) {
  const { register, handleSubmit, watch, setValue } = useForm();

  useEffect(() => {
    if (open) return setValue("name", phase?.name);
    setValue("name", null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const phaseNameInput = watch("name");

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <FormGroup>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="フェーズ名"
            fullWidth
            required
            variant="outlined"
            defaultValue={phase?.name}
            helperText={!phaseNameInput && "必須項目です"}
            {...register("name")}
          />
          <Tooltip placement="left" title="現場で写真を撮らないと次へ進めない">
            <FormControlLabel
              label="写真必須"
              control={
                <Checkbox
                  defaultChecked={phase?.requiresPhoto}
                  {...register("requiresPhoto")}
                />
              }
            />
          </Tooltip>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button onClick={handleSubmit(onSubmit)}>保存</Button>
      </DialogActions>
    </Dialog>
  );
}
