import { Controller, useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";

export default function Alert() {
  const { control } = useFormContext();

  return (
    <Controller
      render={({ field }) => (
        <TextField
          multiline
          rows={3}
          label="注意事項"
          variant="outlined"
          helperText="要注意案件の場合、こちらで記名してください"
          {...field}
        />
      )}
      control={control}
      name="alert"
    />
  );
}
